import { ApolloQueryResult, FetchMoreQueryOptions } from '@apollo/client';
import { useVirtualizer } from '@tanstack/react-virtual';
import get from 'lodash/get';
import debounce from 'lodash.debounce';

import {
  Children,
  cloneElement,
  FC,
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useIntersectionObserver } from 'usehooks-ts';

import SVG from '@/components/data-display/SVG';
// import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import Modal from '@/components/feedback/Modal';

import { a11yClick } from '@/utils/a11y';
import { classNames } from '@/utils/classNames';

const takeItems = 24;

type OnReachEndAction = (cancelObserving: () => void) => void;

type OnReachEndLoadMoreOptions = {
  dataItemsPath?: string;
  fetcher: (
    options: FetchMoreQueryOptions<{ skip: number; take: number }>
  ) => Promise<ApolloQueryResult<any>>;
  queryVariables: { take?: number };
  resultsLength: number;
};

export type OnReachEnd =
  | {
      loadMoreOptions: OnReachEndLoadMoreOptions;
      action?: OnReachEndAction;
    }
  | {
      action: OnReachEndAction;
      loadMoreOptions?: OnReachEndLoadMoreOptions;
    };

interface CellProps extends HTMLProps<HTMLTableCellElement> {
  children?: ReactNode;
  modalTitle?: string;
  truncate?: boolean;
}

interface HeadCellProps extends HTMLProps<HTMLTableCellElement> {
  children?: ReactNode;
  sortKey?: string;
  activeSortParams?: { key: string; order: string } | undefined | null;
}

interface RowProps extends HTMLProps<HTMLTableRowElement> {
  children?: ReactNode;
}

interface BodyProps extends HTMLProps<HTMLTableSectionElement> {
  children?: ReactNode;
  loading?: boolean;
  onReachEnd?: OnReachEnd;
  virtualized?: boolean;
}

interface HeadProps extends HTMLProps<HTMLTableSectionElement> {
  children?: ReactNode;
  onSort?: (sortParams: { key: string; order: string }) => void;
  sortParams?: { [key: string]: string } | undefined | null;
}

interface TableProps extends Omit<HTMLProps<HTMLTableElement>, 'children'> {
  children: ReactNode;
  dataTestId?: string;
  hideScrollbar?: boolean;
  loading?: boolean;
  onReachEnd?: OnReachEnd;
  wrapperClassName?: string;
  onSort?: (sortParams: { key: string; order: string }) => void;
  sortParams?: { [key: string]: string } | undefined | null;
  virtualized?: boolean;
}

type TableComponent = FC<TableProps> & {
  Body: FC<BodyProps>;
  Cell: FC<CellProps>;
  Head: FC<HeadProps>;
  HeadCell: FC<HeadCellProps>;
  Row: FC<RowProps>;
};

const Cell: FC<CellProps> = ({
  children,
  className,
  modalTitle,
  truncate,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const tdRef = useRef<any>(null);

  useEffect(() => {
    const cell = tdRef.current;
    // NOTE: this is not working when render in tab with display:none;
    // TODO: Needs to rework this or tabs implementation
    if (truncate && cell.scrollWidth > cell.clientWidth) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [children, truncate]);

  const openModal = () => {
    if (isTruncated) {
      setIsOpen(true);
    }
  };

  return (
    <>
      {isTruncated && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          // TODO: Needs to rework for automatic getting title for modal
          title={modalTitle}
        >
          {children}
        </Modal>
      )}
      <td
        ref={tdRef}
        onClick={openModal}
        onKeyDown={e => a11yClick(e, openModal)}
        className={classNames(
          'px-3 py-2 bg-surface flex-1 border-b',
          className,
          truncate && 'truncate',
          isTruncated && 'cursor-help hover:underline'
        )}
        {...rest}
      >
        {children}
      </td>
    </>
  );
};

const HeadCell: FC<HeadCellProps> = ({
  children,
  className,
  sortKey,
  activeSortParams,
  scope = 'col',
  ...rest
}) => {
  const { key, order } = activeSortParams || {};

  return (
    <th
      scope={scope}
      className={classNames(
        'py-3.5 px-3 flex-1 border-b',
        className,
        sortKey && 'relative cursor-pointer select-none group/th'
      )}
      {...rest}
    >
      <div>
        {children}

        {sortKey && (
          <div
            className={classNames(
              'inline-block align-middle ml-1 h-full group-hover/th:opacity-100',
              key !== sortKey && 'opacity-0'
            )}
          >
            <SVG
              size="xs"
              icon={
                key !== sortKey || order === 'asc'
                  ? 'sort-alpha-asc'
                  : 'sort-alpha-desc'
              }
            />
          </div>
        )}
      </div>
    </th>
  );
};

const Row: FC<RowProps> = forwardRef(
  ({ className, children, ...rest }, ref) => {
    return (
      <tr
        className={classNames('group-[.table-fixed]/table:flex', className)}
        {...rest}
        ref={ref}
      >
        {children}
      </tr>
    );
  }
);

Row.displayName = 'Row';

const Body: FC<BodyProps> = forwardRef(
  (
    { children, className, loading, onReachEnd, virtualized, ...rest },
    ref: any
  ) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isObservingStopped, setIsObservingStopped] = useState(false);

    const { isIntersecting, ref: targetRef } = useIntersectionObserver();

    useEffect(() => {
      setIsVisible(isIntersecting);
    }, [isIntersecting]);

    const childrenArray = Children.toArray(children);

    if (virtualized && onReachEnd) {
      childrenArray.push(
        <tr className="group-[.table-fixed]/table:flex" key="table-full">
          {!loading && isObservingStopped && childrenArray.length > 0 && (
            <td
              colSpan={999}
              className="group-[.table-fixed]/table:w-full text-center py-2"
            >
              Showing all {childrenArray.length} item
              {childrenArray.length === 1 ? '' : 's'}
            </td>
          )}

          {loading && (
            <td
              colSpan={999}
              className="group-[.table-fixed]/table:w-full text-center py-2"
            >
              <LoadingSpinner
                spaceClassName="m-0"
                wrapperClassName="flex justify-center"
              />
            </td>
          )}
        </tr>
      );
    }

    const rowVirtualizer = useVirtualizer({
      count: childrenArray.length,
      estimateSize: () => 37,
      getScrollElement: () => virtualized && ref.current,
      measureElement:
        typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
          ? element => element?.getBoundingClientRect().height
          : undefined,
      overscan: 5,
    });

    useEffect(() => {
      if (!loading && isVisible && !isObservingStopped && onReachEnd) {
        const { action, loadMoreOptions } = onReachEnd;

        if (action) {
          action(() => {
            setIsObservingStopped(true);
          });
        }

        if (loadMoreOptions) {
          const { dataItemsPath, fetcher, queryVariables, resultsLength } =
            loadMoreOptions;
          const { take = takeItems } = queryVariables;

          if (resultsLength >= take) {
            setIsVisible(false);

            fetcher({
              variables: {
                skip: resultsLength || take,
                take: take,
              },
            })
              .then(response => {
                const data = response.data;
                const itemsPath = dataItemsPath || Object.keys(data)[0];
                const items = get(data, itemsPath, []);

                if (items.length < take) {
                  setIsObservingStopped(true);
                }
              })
              .catch(() => {
                setIsObservingStopped(true);
              });
          } else {
            setIsObservingStopped(true);
          }
        }
      }
    }, [isVisible, isObservingStopped, loading, onReachEnd]);

    useEffect(() => {
      if (isObservingStopped) {
        setIsObservingStopped(false);
      }
    }, [children]);

    const virtualizedChildren = rowVirtualizer
      .getVirtualItems()
      .map(virtualRow => {
        const row = childrenArray[virtualRow.index] as ReactElement;

        return cloneElement(row, {
          'data-index': virtualRow.index,
          ref: rowVirtualizer.measureElement,
          style: {
            left: 0,
            position: 'absolute',
            top: 0,
            transform: `translateY(${virtualRow.start}px)`,
            width: '100%',
          },
        });
      });

    return (
      <tbody
        className={classNames(
          'whitespace-nowrap bg-surface text-sm relative',
          virtualized && 'block',
          className
        )}
        style={virtualized ? { height: rowVirtualizer.getTotalSize() + 1 } : {}}
        {...rest}
      >
        {virtualized ? virtualizedChildren : childrenArray}

        {!virtualized && onReachEnd && (
          <tr className="group-[.table-fixed]/table:flex" key="table-full">
            {!loading && isObservingStopped && childrenArray.length > 0 && (
              <td
                colSpan={999}
                className="group-[.table-fixed]/table:w-full text-center py-2"
              >
                Showing all {childrenArray.length} item
                {childrenArray.length === 1 ? '' : 's'}
              </td>
            )}

            {loading && (
              <td
                colSpan={999}
                className="group-[.table-fixed]/table:w-full text-center py-2"
              >
                <LoadingSpinner
                  spaceClassName="m-0"
                  wrapperClassName="flex justify-center"
                />
              </td>
            )}
          </tr>
        )}

        {onReachEnd && (
          <tr
            className={classNames(
              '!border-0 w-full h-[2px]',
              virtualized && 'absolute bottom-0'
            )}
            ref={targetRef}
          >
            <td colSpan={999}></td>
          </tr>
        )}
      </tbody>
    );
  }
);

Body.displayName = 'Body';

const Head: FC<HeadProps> = ({
  className,
  children,
  sortParams,
  onSort,
  ...rest
}) => {
  const rowChildrenArray = Children.toArray(children) as ReactElement[];

  return (
    <thead
      className={classNames(
        'bg-[#f5f5f5] text-left text-xs font-semibold whitespace-nowrap sticky z-[1] top-[-1px]',
        className
      )}
      {...rest}
    >
      {onSort
        ? rowChildrenArray.map(rowChild => {
            const cellChildrenArray = Children.toArray(
              rowChild.props.children
            ) as ReactElement[];

            return cloneElement(rowChild, {
              children: cellChildrenArray.map(cellChild => {
                const propSortKey = cellChild.props.sortKey;
                const propOnClick = cellChild.props.onClick;

                const activeSortKey = Object.keys(sortParams || {})[0];
                const activeSortOrder = sortParams?.[activeSortKey];

                return propSortKey
                  ? cloneElement(cellChild, {
                      activeSortParams: {
                        key: activeSortKey,
                        order: activeSortOrder,
                      },
                      onClick: e => {
                        let newSortParams: any = {};

                        if (activeSortKey === propSortKey) {
                          newSortParams = {
                            key: propSortKey,
                            order: activeSortOrder === 'asc' ? 'desc' : 'asc',
                          };
                        } else {
                          newSortParams = {
                            key: propSortKey,
                            order: 'asc',
                          };
                        }

                        onSort(newSortParams);

                        if (propOnClick) propOnClick(e);
                      },
                    })
                  : cellChild;
              }),
            });
          })
        : rowChildrenArray}
    </thead>
  );
};

export const Table: TableComponent = ({
  children,
  className,
  dataTestId = 'container-table',
  hideScrollbar,
  loading,
  onReachEnd,
  wrapperClassName,
  sortParams,
  onSort,
  virtualized = false, // When true, the table will be "fixed" and the width of the columns can be changed by adding a max-width to the HeadCell/Cell
  ...rest
}) => {
  const shadowWrapperRef = useRef(null);
  const wrapperRef = useRef(null);
  const wrapperScrollLeftRef = useRef(0);

  const childrenArray = Children.toArray(children);

  const TableHead = childrenArray[0] as ReactElement;
  const TableBody = childrenArray[1] as ReactElement;

  if (!TableHead || !TableBody) {
    throw new Error(
      'Table component must have both TableHead and TableBody as children'
    );
  }

  const handleShowShadows = useCallback(
    debounce((action?: string) => {
      const wrapper = wrapperRef.current as any;

      if (!wrapper) return;

      // If the scroll event is triggered but the scrollLeft is the same, do nothing
      if (
        action === 'scroll' &&
        wrapper.scrollLeft === wrapperScrollLeftRef.current
      )
        return;

      wrapperScrollLeftRef.current = wrapper.scrollLeft;

      const shadowWrapper = shadowWrapperRef.current as any;

      if (!shadowWrapper) return;

      const scrollLeft = wrapper.scrollLeft;
      const scrollWidth = wrapper.scrollWidth;
      const clientWidth = wrapper.clientWidth;
      const scrollHeight = wrapper.scrollHeight;
      const clientHeight = wrapper.clientHeight;

      // Gap to show shadow
      const gap = 10;

      const isOverflowY = scrollHeight > clientHeight;

      // If there is (no) vertical overflow then add (remove) the class to unshift (shift) the right shadow
      if (isOverflowY) {
        shadowWrapper.classList.remove('overflow-shadow-unshifted');
      } else {
        shadowWrapper.classList.add('overflow-shadow-unshifted');
      }

      const isOnLeft = scrollLeft <= gap;
      const isOnRight = scrollLeft + clientWidth >= scrollWidth - gap;

      if (isOnLeft) {
        shadowWrapper.classList.remove('overflow-shadow-left');
      } else {
        shadowWrapper.classList.add('overflow-shadow-left');
      }

      if (isOnRight) {
        shadowWrapper.classList.remove('overflow-shadow-right');
      } else {
        shadowWrapper.classList.add('overflow-shadow-right');
      }
    }, 100),
    [shadowWrapperRef, wrapperRef, wrapperScrollLeftRef]
  );

  useEffect(() => {
    // Initial call to show right shadow if needed
    handleShowShadows();

    window.addEventListener('resize', () => handleShowShadows());

    return () => {
      window.removeEventListener('resize', () => handleShowShadows());
    };
  }, []);

  return (
    // Extra div with .overflow-shadow is needed to show shadows
    <div
      className="relative w-full max-h-full overflow-hidden overflow-shadow"
      ref={shadowWrapperRef}
    >
      <div
        data-testid={dataTestId}
        className={classNames(
          'w-full border h-full overflow-auto relative',
          hideScrollbar && 'scrollbar-hide',
          wrapperClassName
        )}
        ref={wrapperRef}
        onScroll={() => handleShowShadows('scroll')}
      >
        <table
          className={classNames(
            'min-w-full group/table border-separate border-spacing-0 relative',
            virtualized && 'table-fixed',
            className
          )}
          {...rest}
        >
          {cloneElement(TableHead, { onSort, sortParams })}

          {cloneElement(TableBody, {
            loading,
            onReachEnd,
            ref: wrapperRef,
            virtualized,
          })}
        </table>
      </div>
    </div>
  );
};

Table.Body = Body;
Table.Cell = Cell;
Table.Head = Head;
Table.HeadCell = HeadCell;
Table.Row = Row;
